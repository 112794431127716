export const CAUTION_TEXT = `본 서비스는 해외주식의 주요 공시내용을 AI를 활용하여 빠르게
          번역 및 요약하여 제공하는 서비스입니다. 해외주식에 대한 투자를
          권유하는 것이 아니며, 투자판단에 참고용으로만 사용하시기 바랍니다.
          제공되는 자료는 해외거래소의 공시 내용을 수집 후 AI를 활용하여 한글
          번역, 요약, 키워드 추출 등을 통해서 작성합니다. 해외거래소의 자료
          수집하는 과정에서 공시자료 수집 누락 및 지연이 있을 수 있으며,
          자료작성 과정에서 정보의 왜곡, 누락, 오류, 지연 등이 발생할 수
          있습니다. 본 자료는 원본 내용과의 정확성 및 일치성을 보장하지 않으니,
          본문의 [원문 페이지] 바로가기 버튼을 통해 공시 원본 문서를 반드시
          확인하시기 바랍니다.`;

export const IS_IMAGE_CAUTION_TEXT =
  "해당 공시는 요약 내용에 따라, 해석의 차이가 클 수 있는 내용입니다. 원문 페이지를 이용 부탁드립니다.";
